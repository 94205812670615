import { graphql } from "gatsby";
import React from "react";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import Promotion from "../components/WhatWeDoPage/Promotion";
import Trusted from "../components/WhatWeDoPage/Trusted";
import WeOffer from "../components/WhatWeDoPage/WeOffer";

const Vendors: React.FC = () => {
  return (
    <DefaultLayout>
      <SEO
        title="What We Do | Ensemble"
        url="https://ensembleai.io/whatwedo"
        desc="we help enterprise organisations build and run advanced data, analytics and AI capabilities based on modern cloud-native technology."
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <Hero
        title="What We Do"
        backgroundColor={["#40b7de", "#1976e0"]}
        description="Experts In Real-Time Analytics and AI"
      />
      <Promotion />
      <WeOffer />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

export default Vendors;
