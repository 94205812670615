import React from "react";

import { ReactComponent as ContentIcon } from "../../images/vendors/icons/content.svg";
import { ReactComponent as BrandingIcon } from "../../images/vendors/icons/branding.svg";
import { ReactComponent as TrainingIcon } from "../../images/vendors/icons/training.svg";
import { ReactComponent as ImplementationIcon } from "../../images/vendors/icons/implementation.svg";

const WeOffer: React.FC = () => {
  return (
    <div className="w-full px-6 lg:pt-0 pt-[9rem] py-10 lg:py-0 flex justify-center bg-gradient-to-br from-[#560E4A] to-[#170737] text-white">
      <div className="max-w-[1140px] w-full lg:pt-[184px] lg:pb-[162px] space-y-8 lg:space-y-[50px]">
        <div className="max-w-[800px] w-full mx-auto space-y-4">
          <h2 className="text-[28px] lg:text-[32px] font-bold text-center">
            Our <span className="underimage">Services</span>
          </h2>
          <p className="text-sm text-center">
            We offer an end-to-end service for the build and run of ClickHouse and real-time analytics:
          </p>
        </div>
        <div className="flex gap-8 lg:gap-6 flex-col lg:flex-row">
          <div className="flex flex-1 gap-6">
            <div className="flex-1 flex flex-col items-center gap-2 w-2/4 lg:w-auto">
              <ContentIcon className="mb-4" />
              <h4 className="text-xl font-semibold text-center">
                Deployment
              </h4>
              <p className="text-sm text-center">
                Deployment and administration of your <a href="https://clickhouse.com">ClickHouse Cloud</a> based platform.
              </p>
            </div>
            <div className="flex-1 flex flex-col items-center gap-2 w-2/4 lg:w-auto">
              <BrandingIcon className="mb-4" />
              <h4 className="text-xl font-semibold text-center">
                Data Engineering
              </h4>
              <p className="text-sm text-center">
                Data integration and transformations from source systems
                using batch or streaming techniques.
              </p>
            </div>
          </div>
          <div className="flex flex-1 gap-6">
            <div className="flex-1 flex flex-col items-center gap-2 w-2/4 lg:w-auto">
              <TrainingIcon className="mb-4" />
              <h4 className="text-xl font-semibold text-center">Analytics</h4>
              <p className="text-sm text-center">
                Developing sophisticated analytics and data science models
                that exploit the unique power of <a href="https://clickhouse.com">ClickHouse Cloud</a>.
              </p>
            </div>
            <div className="flex-1 flex flex-col items-center gap-2 w-2/4 lg:w-auto">
              <ImplementationIcon className="mb-4" />
              <h4 className="text-xl font-semibold text-center">
                Applications
              </h4>
              <p className="text-sm text-center">
                Development of powerful analytical applications that get data
                science out of the lab.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeOffer;
