import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../UI/Button";
import { ReactComponent as ArrowRight } from "../../images/icons/arrow-right.svg";
import FeaturesBanner from "../../components/UI/FeaturesBanner";


const promotionQuery = graphql`
  {
    categories: allMdx(filter: { fields: { source: { eq: "categories" } } }) {
      totalCount
    }
    lessons: allMdx(
      filter: {
        frontmatter: {
          type: {
            in: ["lesson"]
          }
          visibility: {
            ne: "premium"
          }
        }
      }
    ) {
      totalCount
    }
  }
`;

interface IQueryResponse {
  categories: { totalCount: number };
  lessons: { totalCount: number };
}

const Promotion: React.FC = () => {
  const { categories, lessons } = useStaticQuery<IQueryResponse>(promotionQuery);

  return (
    <div className="pt-10 lg:pt-5 px-6 max-w-[1188px] mx-auto space-y-8 my-9 lg:space-y-20 pb-[19px] lg:pb-0">
      <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-[70px] items-center">
        <StaticImage
          className="max-w-[570px] w-full"
          src="../../images/home/clickhouse-5.png"
          alt="What We Do"
          objectFit="contain"
        />
        <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">
          <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text ">Real-Time Data, Analytics and AI <span className="underimage gradient-blue-text ">Applications</span> {" "}</h2>
          <p className="text-base">we help enterprise organisations design, build and run data, analytics and AI solutions.  Our services include rapid environment setup, data integration, and the development of bespoke dashboards, analytics and machine leaerning models. </p>
          <p className="text-base">We offer a fully managed service that includes 24x7 support, predictable costs and a collaborative engagement model between our team and yours.</p>  
        </div>
      </div>

      <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-[70px] items-center">
        <StaticImage
          className="max-w-[570px] w-full"
          src="../../images/home/clickhouse-1.png"
          alt="What We Do 1"
          objectFit="contain"
        />
        <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">
          <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text "><span className="underimage gradient-blue-text ">Development</span> {" "} Of BI, Analytics and AI/ML Models</h2>
          <p className="text-base">Our team includes data analysts, data scientists and AI/ML engineers who can help you build the analytics that turn your business data into business value.</p>
          <p className="text-base">We cater for requirements ranging from vanilla business intelligence through to sophisticated data science led AI/ML solutions that incorporate proactive forecasting and situational detection.</p>
        </div>
      </div>

      <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-[70px] items-center">
        <StaticImage
          className="max-w-[570px] w-full"
          src="../../images/home/clickhouse-5.png"
          alt="promote your cloud data"
          objectFit="contain"
        />
        <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">

          <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text ">{" "}<span className="gradient-blue-text  underimage">Data Engineering</span> and Streaming Data</h2>
          <p className="text-base">We help to extract, transform and load data from your data sources into ClickHouse, effectively deploying a real-time data warehouse that offers a joined up view of your business data.</p>
          <p className="text-base">Our approach is to use <a href="/learn/categories/dataengineering"  class="underline">data engineering techniques</a> and the <a  class="underline" href="/learn/categories/moderndatastack">modern data stack</a> to deliver solutions which have low operational overhead and deliver data with high quality and reliability.</p>

        </div>
      </div>

      <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-[70px] items-center">
        <StaticImage
          className="max-w-[570px] w-full"
          src="../../images/home/clickhouse-4.png"
          alt="What We Do 1"
          objectFit="contain"
        />
        <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">
          <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text ">{" "}Big Data and <span className="underimage gradient-blue-text ">Real-Time</span> Workloads </h2>
          <p className="text-base">We deploy data platforms that meet stringent requirements for big data, low latency and complexity of analytics.  We are experts in streaming data and stream processing to extract value from data as soon as it is generated.</p>
          <p className="text-base">Our systems support operational analytics which can inform your employees of their next best action, and user facing analytics which can be incorporated into your product experiences.</p>
        </div>
      </div>

      <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 lg:gap-[70px] items-center">
        <StaticImage
          className="max-w-[570px] w-full"
          src="../../images/home/clickhouse-5.png"
          alt="promote your cloud data"
          objectFit="contain"
        />
        <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">

          <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text ">Real-Time Application Development With {" "}<span className="gradient-blue-text  underimage">EnsembleJS</span></h2>
          <p className="text-base">Dashboards are too slow and inflexible.  With this in mind, we created and build applications with EnsembleJS, a React based development framework for building web and mobile applications centered around real-time analytics.</p>
        </div>
      </div>

      <FeaturesBanner
        title="Let's Start The Conversation!"
        to="/contact"
        description="Contact us today to discuss how real-time analytics can accelerate your business innovation."
        buttonLabel="Contact Us"
        alternativeDesign
      />

      

    </div>
  );
};

export default Promotion;
